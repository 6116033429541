import React, { useCallback, useState } from "react"
import styled from "@emotion/styled"
import { navigate } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faKey, faUser } from "@fortawesome/free-solid-svg-icons"
import { MomentoLayout } from "~/layouts/momento/MomentoLayout"
import Button from "~/components/button/Button"
import { useBindState } from "~/utils/hooks/useBindState"
import { fetchApi, requireOk } from "~/services/fetchApi"
import { alertError } from "~/utils/alert/alertUtils"
import { css, Global } from "@emotion/react"

export function MomentoLoginScreen() {
  const name = useBindState("")
  const password = useBindState("")

  const [isFetching, setFetching] = useState(false)

  const onLogin = useCallback(() => {
    setFetching(true)
    fetchApi("/momento/login", {
      method: "POST",
      body: JSON.stringify({ name: name.value, password: password.value }),
    })
      .then(requireOk)
      .then(() => {
        navigate("/momento/events")
      })
      .catch(() => {
        setFetching(false)
        alertError("Failed to login.")
      })
  }, [name, password])

  const isValid = name.value && password.value
  const isSubmitDisabled = !isValid || isFetching

  return (
    <MomentoLayout logoLink={"/momento"}>
      <Global styles={loginCss} />
      <Wrapper>
        <LoginContainer>
          <LoginHeader>
            <FontAwesomeIcon icon={faUser} fixedWidth size={"3x"} />
            <FontAwesomeIcon icon={faKey} fixedWidth size={"3x"} />
          </LoginHeader>
          <div className="field">
            <p className="control has-icons-left has-icons-right">
              <input
                className="input"
                type="email"
                placeholder="Email"
                {...name.binders}
              />
              <span className="icon is-small is-left">
                <FontAwesomeIcon icon={faEnvelope} fixedWidth />
              </span>
              <span className="icon is-small is-right">
                {/*<FontAwesomeIcon icon={faKey} fixedWidth />*/}
              </span>
            </p>
          </div>
          <div className="field">
            <p className="control has-icons-left">
              <input
                className="input"
                type="password"
                placeholder="Password"
                {...password.binders}
              />
              <span className="icon is-small is-left">
                <FontAwesomeIcon icon={faKey} fixedWidth />
              </span>
            </p>
          </div>
          <div className="field">
            <p className="control">
              <Button
                variant={"is-primary"}
                disabled={isSubmitDisabled}
                onClick={onLogin}
              >
                Login
              </Button>
            </p>
          </div>
        </LoginContainer>
      </Wrapper>
    </MomentoLayout>
  )
}

const loginCss = css`
  html,
  body {
    background-color: #cecece;
  }
`

const LoginHeader = styled.div`
  margin-bottom: 16px;
  text-align: center;
  svg {
    margin-left: 6px;
    margin-right: 6px;
  }
`

const Wrapper = styled.div`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoginContainer = styled.div`
  max-width: 320px;
  background-color: white;
  //border: 1px solid #eaeaea;
  border-radius: 3px;
  padding: 32px 48px;
  button {
    width: 100%;
  }
`
